import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
//import linkedIn from '../../images/linkedIn.svg'
import './blogs.scss'
import mainBgImageHAICSMC from '../../../assets/images/blogs/mainBgImageHAICSMC.jpeg' 

import facebook from "../../../assets/images/facebook.png"
import twitter from "../../../assets/images/twitter.png"
import linkedIn from "../../../assets/images/linkedin-logo.png"
import favicon from "../../../assets/images/favicon.svg"
// import "../../styles/case-studies.scss"

const blogData = [
    {
        image: mainBgImageHAICSMC,
        author: 'Machstatz',
        date: 'January 7, 2021',
        category: 'Artificial Intelligence',
        title: 'How Artificial Intelligence Can Solve Manufacturing Challenges during Covid-19?',
        content: 'Businesses across the world are facing financial and operational challenges <br/> due to the pandemic. In this scenario, Artificial Intelligence has emerged as <br/> one of the prime solutions for many businesses. Can Artificial Intelligence <br/> revolutionize the manufacturing industry during this Covid-19?',
        blogLink: "/resources/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19",
        fbLink: "https://www.facebook.com/sharer/sharer.php?u=https://machstatz.com/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19",
        linkedInLink: "https://www.linkedin.com/shareArticle?mini=true&url=https://machstatz.com/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19&title=How Artificial Intelligence Can Solve Manufacturing Challenges during Covid‑19?&summary=Businesses across the world are facing financial and operational challenges due to the pandemic.  In this scenario, Artificial Intelligence has emerged as one of the prime  solutions for many businesses. Can Artificial Intelligence revolutionize  the manufacturing industry during this Covid-19?&source=Machstatz",
        twitterLink: "https://twitter.com/intent/tweet?url=https://machstatz.com/blogs/How_Artificial_Intelligence_Can_Solve_Manufacturing_Challenges_during_Covid-19&text=Businesses across the world are facing financial and operational challenges due to the pandemic.  In this scenario, Artificial Intelligence has emerged as one of the prime  solutions for many businesses. Can Artificial Intelligence revolutionize  the manufacturing industry during this Covid-19?"
    }
]



const Blogs = () => (
    <Layout>
        <SEO title="Blogs" />
        <div className="blogs-banner text-center">
            {/* <div className=""> */}
            <h1 className="pt-2">How Artificial Intelligence Can Solve Manufacturing Challenges during Covid-19?</h1>
            <p className="font-14 pt-2">January 7, 2021 - Machstatz</p>
            {/* </div> */}
        </div>
        <div className="container pt-3">
            {blogData.map(item => {
                return (
                    <div className="row" key={item.title}>
                        <div className="col-lg-7 col-md-12">
                            <div className="row">
                                <div className="col-12">
                                    <Link to={item.blogLink}>
                                        <img src={item.image} className="responsive-img border-radius-20"
                                            width="1000" height="400" />
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Link to={item.blogLink}>
                                        <h2 className="blog-head title">{item.title}</h2>
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-inline">
                                    <img src={favicon} className="bubble-img-blog" />
                                    <div className="blog-div">
                                        <span className="p-lg-2 p-md-0 blog-grey-text">{item.author}</span>
                                        <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                        <span className="blog-grey-text p-lg-2 p-md-0">{item.date}</span>
                                        <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                        <span className="blog-grey-text">{item.category}</span>
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div className="col-12">
                                        <p className="read-more p-lg-2 p-md-0" dangerouslySetInnerHTML={{ __html: item.content}}>
                                            
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-2 mt-lg-3 flex-wrap share-wrapper" style={{ width: '100%' }}>
                                    <div className="order-md-3 share-request-text">
                                        <p className="pl-md-2 m-0 text-center">Don't forget to share this story!</p>
                                    </div>
                                    <div className="share-icons mt-2 mt-lg-0 mt-md-0 pr-md-2 text-center" style={{ flex: 'none' }}>
                                        <a className="share-icon" target="_blank" href={item.fbLink} >
                                            <img className="icons" src={facebook}></img>
                                        </a>
                                        <a className="share-icon" target="_blank" href={item.linkedInLink} >
                                            <img className="icons" src={linkedIn}></img>
                                        </a>
                                        <a className="share-icon" target="_blank" href={item.twitterLink}>
                                            <img className="icons" src={twitter}></img>
                                        </a>
                                    </div>
                                    <div className="share-dashes d-none d-md-block">
                                    </div>
                                </div>
                                {/* <div className="text-center d-flex blog-share p-lg-2 p-md-0">
                            <span className="font-14 bold blog-share-text order-md-3 py-2 py-md-0">
                                Don't forget to share this post!
                             </span>
                            <div className="blog-share-icons">
                                <i className="fab fa-facebook-f share-icon"></i>
                                <i className="fab fa-linkedin-in share-icon"></i>
                                <i className="fab fa-twitter share-icon"></i>
                                <i className="fab fa-facebook-messenger"></i>
                            </div>
                            <span className="font-25 flex-fill px-1 d-none d-md-block">
                                <span className="blog-dashed-lines"></span>
                            </span>
                        </div> */}
                            </div>
                            <div className="col-4"></div>
                        </div>
                        <br />
                    </div>
                )
            })}

            {/* <div className="row">
                <div className="col-lg-7 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <img src="/static/images/industry-background.jpg" className="responsive-img border-radius-20"
                                width="1000" height="400" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="blog-head title">Top Amazing Places to Go in Summer</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-inline">
                            <img src="/static/images/bubble-icon.jpg" className="bubble-img-blog" />
                            <div className="blog-div">
                                <span className="p-lg-2 p-md-0 blog-grey-text">JOHN WICK</span>
                                <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                <span className="blog-grey-text p-lg-2 p-md-0">AUGUST 3,2019</span>
                                <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                <span className="blog-grey-text">INDUSTRY</span>
                            </div>
                        </div>
                        <div className="row p-2">
                            <div className="col-12">
                                <p className="read-more  p-lg-2 p-md-0">
                                    text unknown text unknown text unknown text unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                        </p>
                            </div>
                        </div>
                        <div className="text-center d-flex blog-share p-lg-2 p-md-0">
                            <span className="font-14 bold blog-share-text order-md-3 py-2 py-md-0">
                                Don't forget to share this post!
            </span>
                            <div className="blog-share-icons">
                                <i className="fab fa-facebook-f share-icon"></i>
                                <i className="fab fa-linkedin-in share-icon"></i>
                                <i className="fab fa-twitter share-icon"></i>
                                <i className="fab fa-facebook-messenger"></i>
                            </div>
                            <span className="font-25 flex-fill px-1 d-none d-md-block">
                                <span className="blog-dashed-lines"></span>
                            </span>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
                <br />
            </div>
            
            <div className="row">
                <div className="col-lg-7 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <img src="/static/images/industry-background.jpg" className="responsive-img border-radius-20"
                                width="1000" height="400" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="blog-head title">Top Amazing Places to Go in Summer</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-inline">
                            <img src="/static/images/bubble-icon.jpg" className="bubble-img-blog" />
                            <span className="p-lg-2 p-md-0 blog-grey-text">JOHN WICK</span>
                            <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                            <span className="blog-grey-text p-lg-2 p-md-0">AUGUST 3,2019</span>
                            <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                            <span className="blog-grey-text">INDUSTRY</span>
                        </div>
                        <div className="row p-2">
                            <div className="col-12">
                                <p className="read-more  p-lg-2 p-md-0">
                                    text unknown text unknown text unknown text unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                        </p>
                            </div>
                        </div>
                        <div className="text-center d-flex blog-share p-lg-2 p-md-0">
                            <span className="font-14 bold blog-share-text order-md-3 py-2 py-md-0">
                                Don't forget to share this post!
            </span>
                            <div className="blog-share-icons">
                                <i className="fab fa-facebook-f share-icon"></i>
                                <i className="fab fa-linkedin-in share-icon"></i>
                                <i className="fab fa-twitter share-icon"></i>
                                <i className="fab fa-facebook-messenger"></i>
                            </div>
                            <span className="font-25 flex-fill px-1 d-none d-md-block">
                                <span className="blog-dashed-lines"></span>
                            </span>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
                <br />
            </div>
            
            <div className="row">
                <div className="col-lg-7 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <img src="/static/images/industry-background.jpg" className="responsive-img border-radius-20"
                                width="1000" height="400" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="blog-head title">Top Amazing Places to Go in Summer</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-inline">
                            <img src="/static/images/bubble-icon.jpg" className="bubble-img-blog" />
                            <div className="blog-div">
                                <span className="p-lg-2 p-md-0 blog-grey-text">JOHN WICK</span>
                                <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                <span className="blog-grey-text p-lg-2 p-md-0">AUGUST 3,2019</span>
                                <span className="blog-orange-line p-lg-2 p-md-0">____</span>
                                <span className="blog-grey-text">INDUSTRY</span>
                            </div>
                        </div>
                        <div className="row p-2">
                            <div className="col-12">
                                <p className="read-more p-lg-2 p-md-0">
                                    text unknown text unknown text unknown text unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                                    text unknown text unknown text unknowntext unknown text unknown text unknown
                        </p>
                            </div>
                        </div>
                        <div className="text-center d-flex blog-share p-lg-2 p-md-0">
                            <span className="font-14 bold blog-share-text order-md-3 py-2 py-md-0">
                                Don't forget to share this post!
            </span>
                            <div className="blog-share-icons">
                                <i className="fab fa-facebook-f share-icon"></i>
                                <i className="fab fa-linkedin-in share-icon"></i>
                                <i className="fab fa-twitter share-icon"></i>
                                <i className="fab fa-facebook-messenger"></i>
                            </div>
                            <span className="font-25 flex-fill px-1 d-none d-md-block">
                                <span className="blog-dashed-lines"></span>
                            </span>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
                <br />
            </div>
         */}
        </div>
    </Layout>
)

export default Blogs


